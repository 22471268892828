import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';

export default function TableList() {
  const [usuarios, setUsuarios] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "nombre", numeric: false, disablePadding: true, label: 'Nombre'},
    {id: "email", numeric: false, disablePadding: true, label: 'Email'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(nombre, email, acciones) {
    return { nombre, email, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [user_id, setUserId] = React.useState('');
  const [user_name, setUserName] = React.useState('');
  const [user_email, setUserEmail] = React.useState('');
  const [user_pass, setUserPass] = React.useState('');
  const [user_pass_confirm, setUserPassConfirm] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setUserId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getUsuarios();
  }, []);

  async function getUsuarios() {
    setIsLoad(false);
    await API.post("usuarios/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayUsuarios = [];
        res.data.forEach(user => {
          let aux = createData(
            user["nombre"],
            user["email"],
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  user['id'],
                  user['nombre'],
                  user['email']
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(user['id'])}
              />
            </div>)
          );

          arrayUsuarios.push(aux);
        });
        setUsuarios(arrayUsuarios);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de usuarios,')
    });
  }

  function formUsuarios(){
    return(<>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Nombre"
          defaultValue={user_name}
          onChange={event => {
            const { value } = event.target;
            setUserName(value);
          }}
          error={(error && user_name == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Email"
          defaultValue={user_email}
          onChange={event => {
            const { value } = event.target;
            setUserEmail(value);
          }}
          error={(error && user_email == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Contraseña"
          type="password"
          defaultValue={user_pass}
          onChange={event => {
            const { value } = event.target;
            setUserPass(value);
          }}
          error={(error && user_pass == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Confirma Contraseña"
          type="password"
          defaultValue={user_pass_confirm}
          onChange={event => {
            const { value } = event.target;
            setUserPassConfirm(value);
          }}
          error={(error && user_pass_confirm == '') ? true : false}
        />
      </GridItem>
    </>);
  }

  async function createUsuario(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', user_name], ['email', user_email], ['contraseña', user_pass], ['confirmacion de contraseña', user_pass_confirm]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (user_pass !== user_pass_confirm) {
          showSnack('warning', 'Las contraseñas deben ser iguales');
          setIsProcessing(false);
          return false;
        }
        await API.post("usuarios/store", {
          nombre: user_name,
          email: user_email,
          password: user_pass,
          pass_confirm: user_pass_confirm,
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getUsuarios();
            showSnack('success', 'Usuario creado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          console.log(err);
          showSnack('warning', 'Se ha producido un error');
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  };

  function clearForm(){
    setUserId('');
    setUserName('');
    setUserEmail('');
  }

  function loadEdit(id, nombre, email){
    setUserId(id);
    setUserName(nombre);
    setUserEmail(email);
    handleClickOpenEdit();
  }

  async function editUsuario(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', user_name], ['email', user_email]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (user_pass !== '' && user_pass !== user_pass_confirm) {
          showSnack('warning', 'Las contraseñas deben ser iguales');
          setIsProcessing(false);
          return false;
        }
        await API.post("usuarios/update/"+user_id, {
          nombre: user_name,
          email: user_email,
          password: user_pass
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getUsuarios();
            showSnack('success', 'Usuario editado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          console.log(err);
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteUsuario(id){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post("usuarios/destroy/"+id)
      .then(res => {
        if (res.data) {
          handleClose();
          getUsuarios();
          showSnack('success', 'Usuario eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando al usuario')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Usuarios</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de usuarios
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
            <GridItem xs={3} sm={3} md={3}>
              <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                <AddIcon />
                Crear usuario
              </Fab>
            </GridItem>
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getUsuarios()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={usuarios}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formUsuarios()}
          onConfirm={() => createUsuario()}
          title="Crear usuario"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formUsuarios()}
          onConfirm={() => editUsuario()}
          title="Editar usuario"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteUsuario(user_id)}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar el usuario?"  />
      </div>
    </GridContainer>
  );
}
