import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.css";
import Login from "components/Login";
import LoginAdmin from "components/LoginAdmin";
import PARAMS from "utils/PARAMS";
import API from "utils/API";
import GridContainer from "components/Grid/GridContainer";
import Loader from 'react-loader-spinner';
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import { createBrowserHistory } from "history";
import Admin from "layouts/Admin";
import User from "layouts/User";
import NotFound from './NotFound';

const hist = createBrowserHistory();

class App extends Component {
  state = {
    apiToken: localStorage.getItem("apiToken") || "",
    user: localStorage.getItem("userType") || "participante",
    admin: false,
    load: false,
  };

  setApiToken = data => {
    this.setState({ apiToken: data.token });
  };
  setUser = data => {
    this.setState({ user: data.user });
  };

  isAdmin = e => {
    if (this.state.apiToken === "") {
      var url = 'isAdminGuest';
    }else{
      var url = 'isAdminAuth';
    }
    API.post(url)
    .then(res => {
      if (res.data) {
        this.setState({ admin: true });
      }
      this.setState({ load: true });
    })
    .catch(err => {
      console.log(err);
    });
  }

  componentDidMount(){
    this.isAdmin();
  }

  render() {
    if (this.state.load) {
      var url = PARAMS.baseUrl + 'administrador';
      if ((window.location.href === url && this.state.apiToken === "") || this.state.admin) {
        return (
          <>
            {this.state.apiToken === "" && <LoginAdmin setApiToken={this.setApiToken} setUserType={this.user} />}
            {this.state.apiToken !== "" && (
              <>
                <Router history={hist}>
                  <Switch>
                    <Route path="/administrador" component={Admin} />
                    <Redirect from="/" to="/administrador/dashboard" />
                    <Route component={ NotFound }/>
                  </Switch>
                </Router>
              </>
            )}
          </>
        );
      }else{
        return (
          <>
            {this.state.apiToken === "" && <Login setApiToken={this.setApiToken} />}
            {this.state.apiToken !== "" && (
              <>
                <Router history={hist}>
                  <Switch>
                    <Route path="/participante" component={User} />
                    <Redirect from="/" to="/participante/index" />
                    <Route component={ NotFound }/>
                  </Switch>
                </Router>
              </>
            )}
          </>
        );
      }
    }else{
      return(
        <GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
          <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />
        </GridContainer>
      )
    }

  }
}

export default App;
