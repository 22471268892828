import React from 'react';
import Fab from '@material-ui/core/Fab';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PARAMS from "utils/PARAMS";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function Excel(props) {
  const { csvData, fileName, label } = props;

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return(
    <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={(e) => exportToCSV(csvData,fileName)}>
      <CloudDownloadIcon style={{marginRight: '10px', color: '#fff'}} />
      {label}
    </Fab>
  )

}
