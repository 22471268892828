import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "moment/locale/es";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// Icons
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PeopleIcon from '@material-ui/icons/People';

Moment.locale('es');

export default function Respuestas() {
  const [respuestas, setRespuestas] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "usuario", date: false, disablePadding: true, label: 'Usuario'},
    {id: "acesso", date: false, disablePadding: true, label: 'Acesso'},
    {id: "fecha", date: true, disablePadding: true, label: 'Fecha de Creación'},
    {id: "completo", date: false, disablePadding: true, label: 'Completo'},
    {id: "acciones", date: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(usuario, acceso, fecha, completo, acciones) {
    return { usuario, acceso, fecha, completo, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [toSeach, setToSearch] = React.useState('');
  const [respuestaId, setRespuestaId] = React.useState('');
  const [completados, setCompletados] = React.useState(0);

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setRespuestaId(id);
  };
  const handleClose = () => {
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: '5px',
    },
  };

  const useStyles = makeStyles(styles);

  async function getRespuestas() {
    setIsLoad(false);
    await API.post("respuestas/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayRespuestas = [];
        res.data.forEach(respuesta => {
          let aux = createData(
            respuesta["email"],
            respuesta["token"],
            Moment(respuesta["created_at"]).format('DD-MM-YYYY HH:mm:ss'),
            ((respuesta["completo"]) ? 'Si' : 'No'),
            (<div className={classes.root}>
              <Actions
                del={true}
                onDelete={() => handleClickOpenConfirm(respuesta['id'])}
              />
            </div>)
          );
          arrayRespuestas.push(aux);
        });
        setRespuestas(arrayRespuestas);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error, en la carga de respuestas');
    });
  }

  async function getTotalCompletado() {
    await API.post("respuestas/getTotalCompletado", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data) {
        setCompletados(res.data);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error, en la carga de respuestas');
    });
  }

  async function deleteRespuestas() {
    await API.post(`respuestas/destroy/${respuestaId}`)
    .then(res => {
      getRespuestas();
      handleClose();
      showSnack('success', 'Eliminado correctamente');
    })
    .catch(err => {
      if (err.response) {
        showSnack('warning', err.response.error);
      }
    });
  }

  useEffect(() => {
    getRespuestas();
    getTotalCompletado();
  }, []);

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Respuestas</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de respuestas
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getRespuestas()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} >
              <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended">
                <PeopleIcon /> Total Completados: {completados}
              </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={respuestas}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteRespuestas(respuestaId)}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar al participante y sus respuestas?"  />
      </div>
    </GridContainer>
  );
}
