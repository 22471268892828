import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import API from 'utils/API';
import PARAMS from 'utils/PARAMS';

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }
  const handleOpen = () => {
    if (localStorage.getItem("user") && localStorage.getItem("pass")) {
      setOpen(true);
    }else{
      handleLogout();
    }
  }

  const userType = localStorage.getItem("userType");

  function confirmTextLogout(){
    return(
      <GridItem xs={12} sm={12} md={12} align="center">
        <Typography component="h1" variant="h5">
          Usuario: <strong>{localStorage.getItem("user")}</strong>
        </Typography>
        <Typography component="h1" variant="h5">
          Contraseña: <strong>{localStorage.getItem("pass")}</strong>
        </Typography>
        <Typography component="h1" variant="h6" style={{color: 'red'}}>
          * Recuerda tus credenciales si pretendes entrar más tarde, si no tendrás que cumplimentar los cuestionarios de nuevo
        </Typography>
      </GridItem>
    )
  }

  const handleLogout = () => {
    API.get('logout');
    localStorage.removeItem('apiToken');
    localStorage.removeItem('userType');
    window.location.replace(PARAMS.baseUrl);
  };
  const handleUserProfile = () => {
    window.location.replace(PARAMS.baseUrl+'admin/user_profile');
  };
  const handleSettings = () => {
    window.location.replace(PARAMS.baseUrl+'admin/settings');
  };
  const handleIndex = () => {
    window.location.replace(PARAMS.baseUrl+'participante/index');
  };
  const handleInit = () => {
    window.location.replace(PARAMS.baseUrl+'participante/formInit');
  };
  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          style={{marginLeft:((window.innerWidth > 959) ? -80 : 0)}}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Perfil </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {userType === 'administrador' ? <>
                      <MenuItem
                        onClick={handleUserProfile}
                        className={classes.dropdownItem}
                      >
                        Perfil
                      </MenuItem>
                      <MenuItem
                        onClick={handleSettings}
                        className={classes.dropdownItem}
                      >
                        Ajustes
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={() => handleLogout()}
                        className={classes.dropdownItem}
                      >
                        Cerrar sesión
                      </MenuItem>
                    </> :<>
                      <MenuItem
                        onClick={handleIndex}
                        className={classes.dropdownItem}
                      >
                        Inicio
                      </MenuItem>
                      <MenuItem
                        onClick={handleInit}
                        className={classes.dropdownItem}
                      >
                        Datos Iniciales
                      </MenuItem>
                      <Divider light />
                      <MenuItem
                        onClick={() => handleOpen()}
                        className={classes.dropdownItem}
                      >
                        Cerrar sesión
                      </MenuItem>
                    </>}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
        <Modal
          open={open}
          onConfirm={() => handleLogout()}
          onCancel={() => handleClose()}
          content={confirmTextLogout()}
          confirmText="Salir"
          confirmIcon={<></>} />
      </div>
    </div>
  );
}
