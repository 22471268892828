import React, { useState } from "react";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { showSnack } from "utils/helperFunctions";
import Landing from "components/Landing";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';

import logoAbay from "assets/img/Logo_Abay.png";
import loginTop from "assets/img/login_top.png";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image1: {
    backgroundImage: `url(${loginTop})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundPosition: "bottom",
    backgroundSize: '120%'
  },
  image2: {
    backgroundImage: `url(${loginTop})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundPosition: "0% 25%",
    backgroundSize: '120%'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Login(props) {
  const [user, setUser] = useState({});
  const [accesoValido, setAccesoValido] = useState(false);
  const [accesoToken, setAccesoToken] = useState({});
  const [accesoUser, setAccesoUser] = useState('');
  const [accesoPass, setAccesoPass] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const [errorAcceso, setErrorAcceso] = useState(false);
  const [error, setError] = useState('');
  const [acceso, setAcceso] = useState('');

  const classes = useStyles();

  const login = e => {
    setError('');
    setErrorMail(false);
    setErrorPass(false);

    if (accesoUser != '' && accesoPass != '') {
      API.post("login", {
        'email': accesoUser,
        'password': accesoPass,
        'userType': 'participante'
      })
      .then(res => {
        localStorage.setItem("datos_iniciales", res.data.datos_iniciales);
        localStorage.setItem("apiToken", res.data.token);
        localStorage.setItem("userType", 'participante');
        showSnack('info', 'Bienvenido');
        window.location.reload(false);
        props.setApiToken(res.data);
        props.setUserType('participante');
      })
      .catch(err => {
        if (err.response) {
          if(err.response.data.error.email || err.response.data.error.password){
            if(err.response.data.error.email){
              setError(err.response.data.error.email['0']);
              setErrorMail(true);
            }
            if(err.response.data.error.password){
              setError(err.response.data.error.password['0']);
              setErrorPass(true);
            }
          }else{
            setError(err.response.data.error);
            setErrorMail(true);
            setErrorPass(true);
          }
        }
      });
    }else{
      if (accesoUser == '') {
        setErrorMail(true);
      }
      if (accesoPass == '') {
        setErrorPass(true);
      }
    }
  };

  const getUser = e => {
    setError('');
    if (accesoUser == '' && accesoPass == '') {
      API.post("accesos/getUser", {
        token: accesoToken
      })
      .then(res => {
        localStorage.setItem("user", res.data.user);
        localStorage.setItem("pass", res.data.pass);
        setAccesoUser(res.data.user);
        setAccesoPass(res.data.pass);
        setAcceso('generate');
      })
      .catch(err => {
        if (err.response) {
          if(err.response.data.error.email || err.response.data.error.password){
            if(err.response.data.error.email){
              setErrorMail(err.response.data.error.email['0']);
            }
            if(err.response.data.error.password){
              setErrorPass(err.response.data.error.password['0']);
            }
          }else{
            setError(err.response.data.error);
          }
        }
      });
    }else{
      setAcceso('generate')
    }
  }

  const validarAcceso = e => {
    setErrorAcceso(false);
    setError('');
    if (accesoToken != '') {
      API.post("accesos/isValid", {
        token: accesoToken
      })
      .then(res => {
        setAccesoValido(true);
      })
      .catch(err => {
        setErrorAcceso(true);
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error);
        }else{
          showSnack('warning', 'Se ha producido un error, intentelo más tarde');
        }
      });
    }else{
      setError('Introduce un código de acceso');
      setErrorAcceso(true);
    }
  }

  function backToLanding(){
    setAcceso('');
    setAccesoUser('');
    setAccesoPass('');
  }

  return (
    <Grid container component="main" className={classes.root}>
      {(acceso == '' && accesoValido) ?
        <Landing onLogin={() => setAcceso('login')} onGetClave={() => getUser()} />
      : <>
        <CssBaseline />
        <Grid item xs={true} sm={true} md={7}  >
          <Grid container className={classes.image1}>
            <Typography variant="h3" align="left" color="textSecondary" paragraph style={{height: '200px'}}></Typography>
          </Grid>
          <Grid container style={{padding: '45px'}}>
            <Typography variant="h3" align="left" color="textSecondary" paragraph style={{color: '#999', textShadow: '2px 2px 3px rgba(255,255,255,0.7)'}}>
              Bienvenido a nuestro laboratorio de ideas
            </Typography>
            <Typography variant="h4" align="left" color="textSecondary" paragraph style={{color: '#999', textShadow: '2px 2px 3px rgba(255,255,255,0.7)'}}>
              ¡Gracias por colaborar con nosotros!
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph style={{color: '#999', textShadow: '2px 2px 3px rgba(255,255,255,0.7)'}}>
              Te recordamos que tu colaboración es totalmente anónima.
              Introduce la palabra que te hemos enviado para obtener tus claves personales.
            </Typography>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid container className={classes.image2}>
              <Typography variant="h3" align="left" color="textSecondary" paragraph style={{height: '200px'}}></Typography>
            </Grid>
          </Hidden>
        </Grid>
        <Grid container style={{padding: '45px', position: 'absolute', bottom: '15px'}}>
        <Hidden only={['xs', 'sm']}>
          <img src={logoAbay} style={{backgroundColor: '#fff', width: '6%'}}/>
        </Hidden>
        </Grid>
        {accesoValido ? <>
          {acceso == 'generate' &&
            <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square style={{minHeight: 400}}>
              <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                  Usuario: {accesoUser}
                </Typography>
                <Typography component="h1" variant="h5">
                  Contraseña: {accesoPass}
                </Typography>
                <Typography component="h1" variant="h6" style={{color: 'red'}}>
                  * Recuerda tus credenciales si pretendes entrar más tarde
                </Typography>
              </div>
              <div className={classes.paper}>
                <Button
                  onClick={() => login()}
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{backgroundColor: PARAMS.firstColor}}
                >
                  Ir a los cuestionarios
                </Button>
              </div>
            </Grid>
          }
          {acceso == 'login' &&
            <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square style={{minHeight: 400}}>
              <div className={classes.paper}>
                <Avatar className={classes.avatar} style={{backgroundColor: PARAMS.firstColor}}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Iniciar sesión
                </Typography>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Usuario"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  defaultValue={accesoUser}
                  onChange={event => {
                    const { value } = event.target;
                    setAccesoUser(value);
                  }}
                  error = {errorMail}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  defaultValue={accesoPass}
                  onChange={event => {
                    const { value } = event.target;
                    setAccesoPass(value);
                  }}
                  helperText={error}
                  error = {errorPass}
                />
                {error.length !== 0 && <FormHelperText error>{error}</FormHelperText>}
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => login()}
                  style={{backgroundColor: PARAMS.firstColor}}
                >
                  Entrar
                </Button>
              </div>
              <div className={classes.paper}>
                <Button
                  onClick={() => backToLanding()}
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{backgroundColor: PARAMS.firstColor}}
                >
                  Volver
                </Button>
              </div>
            </Grid>
          }
        </> : <>
          <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square style={{display: 'flex', alignItems: 'center', minHeight: 400}}>
            <div className={classes.paper} style={{width: '100%', marginTop: '0px'}}>
              <Avatar className={classes.avatar} style={{backgroundColor: PARAMS.firstColor}}>
                <LockOutlinedIcon />
              </Avatar>
              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="token"
                  label="Código"
                  name="token"
                  autoComplete=""
                  autoFocus
                  onChange={event => {
                    const { value } = event.target;
                    setAccesoToken(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      validarAcceso();
                    }
                  }}
                  helperText={error}
                  error = {errorAcceso}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => validarAcceso()}
                  style={{backgroundColor: PARAMS.firstColor}}
                >
                  Acceder
                </Button>
              </form>
            </div>
          </Grid>
        </>}
      </>}
    </Grid>
  );
}
