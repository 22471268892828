import React, { useEffect } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import API from "utils/API";
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import SimpleChart from "components/Chart/SimpleChart";
import RadarChart from "components/Chart/RadarChart";
import VerticalBarChart from "components/Chart/VerticalBarChart";
import Excel from "components/Excel/Excel";

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PARAMS from "utils/PARAMS";

// Core
import { makeStyles } from "@material-ui/core/styles";
import Fab from '@material-ui/core/Fab';

export default function TableList() {
  const [isLoad, setIsLoad] = React.useState(false);
  const [excelData, setExcelData] = React.useState([]);

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: '5px',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getExcel();
  }, []);


  async function getExcel() {
    setIsLoad(false);
    await API.post("respuestas/excel")
    .then(res => {
      if (res.data.length > 0) {
        setExcelData(res.data);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem>
          {(isLoad ?
            <Excel csvData={excelData} fileName="registro_de_respuestas" label="Descargar Excel"/>
          :
            <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />
          )}
      </GridItem>
    </GridContainer>
  );
}
