import React, { useEffect } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { showSnack } from 'utils/helperFunctions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';

// Core
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export default function Swls() {
  const [content, setContent] = React.useState(null);
  const [respuestas, setRespuestas] = React.useState(null);
  const [isLoad, setIsLoad] = React.useState(false);

  const styles = {
    table: {
      minWidth: 750,
    },
    root: {
      width: '100%',
      height: '100%',
      padding: '15px'
    },
    flexColCenter: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    flexRowCenter: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    },
    selectedBtn:{
      backgroundColor: PARAMS.firstColor,
      color: '#fff'
    },
    defaultBtn:{
      maxWidth: '200px'
    },
    radioCustom: {
      color: PARAMS.firstColor,
      '&$checked': {
        color: PARAMS.firstColor,
      },
    },
    checked: {}
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  async function responseTo(variable, value) {
    await API.post("respuestas/update/"+variable, {
      value: value
    })
    .then(res => {
      var arrayContent = [];
      if (Object.keys(res.data).length > 0) {
        setRespuestas(res.data);
      }
    })
    .catch(err => {
      if (err.response && err.response.data && err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }
    });
  }

  async function getRespuestas() {
    await API.post("respuestas/showByUser")
    .then((res) => {
      var arrayContent = [];
      if (Object.keys(res.data).length > 0) {
        setRespuestas(res.data);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  async function getForm() {
    await API.post("formularios/getFormByType/4")
      .then((res) => {
      setContent(res.data);
      setIsLoad(true);
    })
    .catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    getForm();
    getRespuestas();
  }, []);

  return (
    <Paper className={classes.root}>
      {(isLoad ?
        <>
          <GridContainer style={{display: 'flex', paddingTop: '15px',  paddingBottom: '15px'}} >
            <GridItem xs={6} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Button onClick={() => window.location.href = PARAMS.baseUrl} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
                Inicio
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'flex-end', padding: '15px'}}>
              <Typography gutterBottom variant="h5" component="h5">
                Más abajo hay afirmaciones con las que puedes estar de acuerdo o en desacuerdo.  Utilizando la siguiente escala, indica tu acuerdo con cada una de ellas.  Por favor, responde a las preguntas de forma abierta y sincera.
              </Typography>
            </GridItem>
          </GridContainer>
          {content.map((data, index) => {
            var result = null;
            if (respuestas) {
              result = respuestas[data.variable];
            }
            var defaultBtn = {maxWidth: '200px'};
            var selectedBtn = {maxWidth: '200px', backgroundColor: PARAMS.firstColor, color: '#fff'};
            return(
              <div key={data.orden}>
                <Typography gutterBottom variant="h5" component="h5">
                  {data.orden}. {data.titulo}
                </Typography>
                <GridContainer style={{display: 'flex', paddingTop: '15px',  paddingBottom: '15px', flexDirection: 'column', padding: '0px 5%'}} >
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '7' ? true : false}
                          onChange={(e) => {e.target.value = "checkedB"; responseTo(data.variable, 7);}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="Completamente de acuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '6' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 6)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="De acuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '5' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 5)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="Más bien de acuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '4' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 4)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="Ni de acuerdo ni en desacuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '3' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 3)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="Más bien en desacuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '2' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 2)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="En desacuerdo"
                    />
                  </GridItem>
                  <GridItem style={{display: 'flex'}}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={result == '1' ? true : false}
                          onChange={(e) =>{e.target.value = "checkedB"; responseTo(data.variable, 1)}}
                          value="checkedB"
                          color="primary"
                          classes={{root: classes.radioCustom, checked: classes.checked}}
                        />
                      }
                      label="Completamente en desacuerdo"
                    />
                  </GridItem>
                </GridContainer>
                <Divider variant="middle" style={{marginTop: '15px', marginBottom: '15px'}}/>
              </div>
            )
          })}
          <GridContainer style={{display: 'flex', paddingTop: '15px',  paddingBottom: '15px'}} >
            <GridItem xs={6} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <Button onClick={() => window.location.href = PARAMS.baseUrl} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
                Inicio
              </Button>
            </GridItem>
          </GridContainer>
        </>
      :
        <>
          <Loader className={classes.flexColCenter} type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
        </>
      )}
    </Paper>
  );
}
