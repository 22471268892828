import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from '@material-ui/core/TextField';
import Loader from 'react-loader-spinner';

import avatar from "assets/img/faces/marc.jpg";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {

  const [isLoad, setIsLoad] = React.useState(false);
  const [nombre, setNombre] = React.useState(null);
  const [rol, setRol] = React.useState(null);
  const [email, setEmail] = React.useState(null);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    await API.post("usuarios/show")
    .then(res => {
      if (Object.keys(res.data).length > 0) {
        setNombre(res.data.nombre);
        setRol(res.data.rol);
        setEmail(res.data.email);
      }
      setIsLoad(true);
    })
    .catch(err => {
      console.log(err);
    });
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
      {(isLoad ?
        <>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card profile>
                      <CardAvatar profile>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={avatar} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>{rol}</h6>
                        <h4 className={classes.cardTitle}>{nombre}</h4>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      label="Nombre"
                      defaultValue={nombre}
                      onChange={event => {
                        const { value } = event.target;
                        setNombre(value);
                      }}
                      fullWidth={true}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      label="Email"
                      defaultValue={email}
                      onChange={event => {
                        const { value } = event.target;
                        setEmail(value);
                      }}
                      fullWidth={true}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contraseña"
                      id="first-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Confirmar Contraseña"
                      id="last-name"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter style={{display: 'flex', justifyContent: 'center'}}>
                <Button color="info">Actualizar perfil</Button>
              </CardFooter>
            </Card>
          </GridItem>
          </>
        :
          <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
              <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={80} width={80} />
            </GridContainer>
          </>
        )}
      </GridContainer>
    </div>
  );
}
