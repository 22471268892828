import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "moment/locale/es";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';

Moment.locale('es');

export default function TableList() {
  const [accesos, setAccesos] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "nombre", numeric: false, disablePadding: true, label: 'Nombre'},
    {id: "token", numeric: false, disablePadding: true, label: 'Clave'},
    {id: "desde", numeric: false, disablePadding: true, label: 'Desde'},
    {id: "hasta", numeric: false, disablePadding: true, label: 'Hasta'},
    {id: "tiempo", numeric: false, disablePadding: true, label: 'Caducidad'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(nombre, token, desde, hasta, tiempo, acciones) {
    return { nombre, token, desde, hasta, tiempo, acciones };
  }

  const today = Moment().format('YYYY-MM-DD HH:mm:ss');
  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [acceso_id, setAccesoId] = React.useState('');
  const [acceso_name, setAccesoName] = React.useState('');
  const [acceso_desde, setAccesoDesde] = React.useState(today);
  const [acceso_hasta, setAccesoHasta] = React.useState(today);
  const [acceso_tiempo, setAccesoTiempo] = React.useState('');
  const [acceso_clave, setAccesoClave] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setAccesoId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
    error: {
      "& label": {
        color: "#f44336"
      }
    }
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getAccesos();
  }, []);

  async function getAccesos() {
    setIsLoad(false);
    await API.post("accesos/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayAccesos = [];
        res.data.forEach(acceso => {
          let aux = createData(
            acceso["nombre"],
            (<div className={classes.root}>
              {acceso["token"]}{' '}
              <CopyToClipboard text={acceso["token"]} onCopy={() => showSnack('info', 'Copiado')}>
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} size="small" aria-label="Copiar al portapapeles">
                  <FileCopyIcon fontSize="small" />
                </Fab>
              </CopyToClipboard>
            </div>),
            Moment(acceso["validez_desde"]).format('DD-MM-YYYY'),
            Moment(acceso["validez_hasta"]).format('DD-MM-YYYY'),
            acceso["tiempo_validez"] + ' días',
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  acceso['id'],
                  acceso["nombre"],
                  acceso["validez_desde"],
                  acceso["validez_hasta"],
                  acceso["tiempo_validez"],
                  acceso["token"]
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(acceso['id'])}
              />
            </div>)
          );
          arrayAccesos.push(aux);
        });
        setAccesos(arrayAccesos);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error, en la carga de accesos');
    });
  }

  function formAcceso(){
    return(<>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Nombre"
          defaultValue={acceso_name}
          onChange={event => {
            const { value } = event.target;
            setAccesoName(value);
          }}
          error={(error && acceso_name == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Días caducidad acceso"
          defaultValue={acceso_tiempo}
          type="number"
          onChange={event => {
            const { value } = event.target;
            setAccesoTiempo(value);
          }}
          error={(error && acceso_tiempo == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={10} sm={10} md={10} style={{marginTop: '10px'}}>
        <TextField
          label="Clave"
          defaultValue={acceso_clave}
          fullWidth={true}
          onChange={event => {
            const { value } = event.target;
            setAccesoClave(value);
          }}
        />
      </GridItem>
      <GridItem xs={2} sm={2} md={2} style={{marginTop: '10px', padding: '0px'}}>
        <Tooltip title="Si este campo se envia vacío, se generará una clave aleatoria" aria- label="add">
          <InfoIcon fontSize='small' style={{color: PARAMS.firstColor, marginRight: '5px', marginTop: '25px'}} />
        </Tooltip>
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils} locale='es'>
          <Grid container>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="DD-MM-YYYY"
              margin="normal"
              id="date-picker-desde"
              label="Desde"
              value={acceso_desde}
              onChange={(value) => {
                setAccesoDesde(value);
              }}
              className={(error && acceso_desde == null) ? classes.error : ""}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils} locale='es'>
          <Grid container>
            <DatePicker
              autoOk
              disableToolbar
              variant="inline"
              format="DD-MM-YYYY"
              margin="normal"
              id="date-picker-hasta"
              label="Hasta"
              value={acceso_hasta}
              onChange={(value) => {
                setAccesoHasta(value);
              }}
            className={(error && acceso_hasta == null) ? classes.error : ""}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </GridItem>
    </>);
  }

  async function createAcceso(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', acceso_name], ['desde', acceso_desde], ['hasta', acceso_hasta], ['caducidad', acceso_tiempo]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("accesos/store", {
          nombre: acceso_name,
          validez_desde: Moment(acceso_desde).format('YYYY-MM-DD 00:00:00'),
          validez_hasta: Moment(acceso_hasta).format('YYYY-MM-DD 00:00:00'),
          tiempo_validez: acceso_tiempo,
          token: acceso_clave
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getAccesos();
            showSnack('success', 'Acceso creado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        setError(true);
        showSnack('warning', validate.message);
      }
    }
  }

  function clearForm(){
    setAccesoId('');
    setAccesoName('');
    setAccesoDesde(null);
    setAccesoHasta(null);
    setAccesoTiempo('');
    setAccesoClave('');
  }

  function loadEdit(id, nombre, validez_desde, validez_hasta, tiempo_validez, clave){
    setAccesoId(id);
    setAccesoName(nombre);
    setAccesoDesde(validez_desde);
    setAccesoHasta(validez_hasta);
    setAccesoTiempo(tiempo_validez);
    setAccesoClave(clave);
    handleClickOpenEdit();
  }

  async function editAcceso(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', acceso_name], ['desde', acceso_desde], ['hasta', acceso_hasta], ['caducidad', acceso_tiempo]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("accesos/update/"+acceso_id, {
          nombre: acceso_name,
          validez_desde: Moment(acceso_desde).format('YYYY-MM-DD 00:00:00'),
          validez_hasta: Moment(acceso_hasta).format('YYYY-MM-DD 00:00:00'),
          tiempo_validez: acceso_tiempo,
          token: acceso_clave
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getAccesos();
            showSnack('success', 'Acceso editado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteAcceso(id){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post("accesos/destroy/"+id)
      .then(res => {
        if (res.data) {
          handleClose();
          getAccesos();
          showSnack('success', 'Acceso eliminado correctamente');
        }
        setIsProcessing(false);
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando el acceso');
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Accesos</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de accesos
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
            <GridItem xs={3} sm={3} md={3}>
              <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                <AddIcon />
                Crear acceso
              </Fab>
            </GridItem>
              <GridItem xs={6} sm={6} md={6} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getAccesos()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={accesos}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formAcceso()}
          onConfirm={() => createAcceso()}
          title="Crear acceso"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formAcceso()}
          onConfirm={() => editAcceso()}
          title="Editar acceso"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteAcceso(acceso_id)}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar el acceso?"  />
      </div>
    </GridContainer>
  );
}
