import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import PARAMS from "utils/PARAMS";

export default class SimpleChartComponent extends PureComponent {

  render() {
    return (
      <LineChart
        width={1000}
        height={300}
        data={this.props.data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="usuario" stroke={PARAMS.firstColor} activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="media" stroke="#82ca9d" />
      </LineChart>
    );
  }
}
