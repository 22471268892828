import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import Dashboard from "views/dashboard.js";
import Usuarios from "views/usuarios/usuarios";
import UserProfile from "views/usuarios/user_profile";
import Settings from "views/usuarios/settings";
import Accesos from "views/accesos/accesos";
import Informes from "views/informes/informes";
import Respuestas from "views/respuestas/respuestas";
import FormIndex from "views/formularios/formularioIndex";
import FormInit from "views/formularios/formularioInicial";
import Swls from "views/formularios/swls";
import BpRyff from "views/formularios/bp_ryff";
import BpCustom from "views/formularios/bp_custom";
import Inteligencias from "views/formularios/inteligencias_multiples";
import Intereses from "views/formularios/intereses_profesionales";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: PeopleIcon,
    component: Dashboard,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: PeopleIcon,
    component: Usuarios,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/accesos",
    name: "Accesos",
    icon: ListAltIcon,
    component: Accesos,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/informes",
    name: "Informes",
    icon: DescriptionIcon,
    component: Informes,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/respuestas",
    name: "Respuestas",
    icon: AssignmentIndIcon,
    component: Respuestas,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/user_profile",
    name: "Perfil Usuario",
    icon: PeopleIcon,
    component: UserProfile,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/settings",
    name: "Ajustes",
    icon: PeopleIcon,
    component: Settings,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/index",
    name: "",
    icon: DescriptionIcon,
    component: FormIndex,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formInit",
    name: "",
    icon: DescriptionIcon,
    component: FormInit,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formulario/swls",
    name: "Satisfación con la vida",
    icon: DescriptionIcon,
    component: Swls,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formulario/bp_ryff",
    name: "Bienestar Psicológico",
    icon: DescriptionIcon,
    component: BpRyff,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formulario/bp_custom",
    name: "Bienestar Psicológico",
    icon: DescriptionIcon,
    component: BpCustom,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formulario/inteligencias_multiples",
    name: "Inteligencias Múltiples",
    icon: DescriptionIcon,
    component: Inteligencias,
    layout: "/participante",
    visible: false
  },
  {
    path: "/formulario/intereses_profesionales",
    name: "Intereses Profesionales",
    icon: DescriptionIcon,
    component: Intereses,
    layout: "/participante",
    visible: false
  },
];

export default dashboardRoutes;
