import React, { useEffect } from "react";
import { unmountComponentAtNode } from 'react-dom';
import Snackbar from "components/Snackbar/Snackbar";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';

export default function CustomSnack(props) {

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackColor, setSnackColor] = React.useState('info');
  const [snackIcon, setSnackIcon] = React.useState(WarningIcon);
  const [snackMessage, setSnackMessage] = React.useState('');

  function alertError(message){
    setSnackOpen(true);
    setSnackColor('danger');
    setSnackIcon(WarningIcon);
    setSnackMessage(message);
    setTimeout(function () {
      unmountComponentAtNode(document.getElementById('aux'));
    }, 3000);
  }

  function alertInfo(message){
    setSnackOpen(true);
    setSnackColor('info');
    setSnackIcon(InfoIcon);
    setSnackMessage(message);
    setTimeout(function () {
      unmountComponentAtNode(document.getElementById('aux'));
    }, 3000);
  }

  function alertSuccess(message){
    setSnackOpen(true);
    setSnackColor('success');
    setSnackIcon(CheckCircleOutlineIcon);
    setSnackMessage(message);
    setTimeout(function () {
      unmountComponentAtNode(document.getElementById('aux'));
    }, 3000);
  }

  useEffect(() => {
    const { type, message } = props;

    switch (type) {
      case 'warning':
        alertError(message);
      break;
      case 'success':
        alertSuccess(message);
      break;
      case 'info':
        alertInfo(message);
      break;
      default:
    }
  }, []);

  return(<Snackbar
    place="br"
    color={snackColor}
    icon={snackIcon}
    message={snackMessage}
    open={snackOpen}
    closeNotification={() => setSnackOpen(false)}
    close
  />);

}
