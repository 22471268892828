import React, { useState, useEffect } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { showSnack } from "utils/helperFunctions";
import Moment from 'moment';

// Core
import { makeStyles } from "@material-ui/core/styles";


export default function Dashboard() {

  const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 180,
    },
    cardHeader:{
      height: '170px',
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    },
    cardBtn:{
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    }
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    showSnack('info', 'Bienvenido');
  }, []);


  const classes = useStyles();

  return (
    <GridContainer style={{display: 'flex', justifyContent: 'center'}}>
      <h1>Panel de Administración</h1>
    </GridContainer>
  );
}
