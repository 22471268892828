import loginImg from "assets/img/login_top.png";
import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/Logo_Abay.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params['baseUrl'] = 'https://abaytalent.aicor.es/';
params['loginImg'] = loginImg;
params['loginAdminImg'] = loginAdminImg;
params['sideBarIcon'] = iconImg;
params['sideBarImg'] = sideBarImg;
// "purple", "blue", "green", "orange", "red" ... modificar en material-dashboard-react.js
params['sideBarColor'] = 'blue';
params['firstColor'] = '#c05e6d';
params['proyectName'] = 'Abay Talent';
params['loaderType'] = 'Triangle';
params['defaultTableLength'] = 10;
params['tableLengthArray'] = [10, 25, 50];

export default params;
