import React, { useEffect, useRef } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { Link, Redirect } from 'react-router-dom';
import Select2 from 'react-select';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

// Core
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default function FormularioInicial() {

  const [isLoad, setIsLoad] = React.useState(false);
  const [isLoadForm, setIsLoadForm] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [enviado, setEnviado] = React.useState(false);
  const [sexo, setSexo] = React.useState(null);
  const [sexOptions, setSexOptions] = React.useState([
    { value: 'Hombre', label: 'Hombre' },
    { value: 'Mujer', label: 'Mujer' }
  ]);
  const [edad, setEdad] = React.useState(null);
  const [errorEdad, setErrorEdad] = React.useState('');
  const [estudio, setEstudio] = React.useState(null);
  const [estudioOptions, setEstudioOptions] = React.useState([]);
  const [estado, setEstado] = React.useState(null);
  const [estadoOptions, setEstadoOptions] = React.useState([]);
  const [ocupacion, setOcupacion] = React.useState(null);
  const [errorOcupacion, setErrorOcupacion] = React.useState(false);
  const [ocupacionArray, setOcupacionArray] = React.useState([]);
  const [ocupacionOptions, setOcupacionOptions] = React.useState([]);
  const [ocupacionYears, setOcupacionYears] = React.useState(null);
  const [errorOcupacionYears, setErrorOcupacionYears] = React.useState('');
  const [selectDisable, setSelectDisable] = React.useState(false);

  const [responsabilidad, setResponsabilidad] = React.useState('');
  const [estres, setEstres] = React.useState('');
  const [componente_social, setComponenteSocial] = React.useState('');
  const [relacion_terceros, setRelacionTerceros] = React.useState('');
  const [jefe_equipo, setJefeEquipo] = React.useState('');

  const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 180,
    },
    cardHeader:{
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    },
    cardBtn:{
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    },
    formControl: {
      minWidth: "100%"
    },
    error: {
      border: " 1px solid #f44336"
    }
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    loadOcupaciones();
    loadFormInit();
  }, []);

  function findLabel(options, value){
    var label = '';
    options.forEach(data => {
      if (data.value == value) {
        label = data.label;
      }
    })
    return label;
  }

  async function loadOcupaciones(ocupacionParent = null, noBack = true) {
    setIsLoad(false);
    setSelectDisable(true);
    await API.post("ocupaciones/index",{
      parent: ocupacionParent
    })
    .then(res => {
      if (res.data.length > 0) {
        var arrayOcupaciones = [];
        res.data.forEach(data => {
          let aux = {};
          aux['value'] = data.id;
          aux['label'] = data.codigo+' '+data.nombre;
          arrayOcupaciones.push(aux);
        })
        setOcupacionOptions(arrayOcupaciones);
        setIsLoad(true);
        if (noBack) {
          ocupacionArray.push(ocupacionParent)
          setOcupacionArray(ocupacionArray);
        }
        setErrorOcupacion(true);
      }else{
        setErrorOcupacion(false);
        myRef.current.onMenuClose();
      }
      setSelectDisable(false);
    })
    .catch(err => {
      console.log(err);
      setSelectDisable(false);
    });
  }

  function lastOcupation(){
    if (ocupacionArray.length > 1) {
      console.log(ocupacionArray);
      ocupacionArray.splice(-1,1);
      setOcupacionArray(ocupacionArray);
      loadOcupaciones(ocupacionArray[ocupacionArray.length - 1], false);
    }
  }

  function validateFormInt(){
    if (!sexo || (sexo && !sexo.value)) {
      showSnack('warning', 'El campo Sexo debe estar relleno');
      setError(true);
      return false;
    }
    if (edad < 18) {
      showSnack('warning', 'Debes ser mayor de 18 años');
      return false;
    }
    if (edad > 120) {
      showSnack('warning', 'La edad no puede ser superior a 120');
      return false;
    }
    if (!edad) {
      setErrorEdad('Rellena este campo');
    }
    if (!estudio || (estudio && !estudio.value)) {
      showSnack('warning', 'El campo Nivel de Estudios debe estar relleno');
      setError(true);
      return false;
    }
    if (!estado || (estado && !estado.value)) {
      showSnack('warning', 'El campo Estado Sentimental debe estar relleno');
      setError(true);
      return false;
    }
    if (errorOcupacion) {
      showSnack('warning', 'Avance hasta un nivel de ocupación de 4 dígitos');
      setError(true);
      return false;
    }
    if (!ocupacionYears) {
      showSnack('warning', 'El campo Años en Ocupación debe estar relleno');
      setErrorOcupacionYears('Rellena este campo');
      return false;
    }
    if (ocupacionYears > 70) {
      showSnack('warning', 'Años de ocupacion no puede ser mayor de 70');
      return false;
    }

    return true;
  }

  async function loadFormInit() {
    setIsLoadForm(false);
    await API.post("respuestas/loadFormInit")
    .then(res => {
      if (res.data) {
        if (res.data.edad) {
          setIsEdit(true);
        }
        if (res.data.estadoOptions) {
          var arrayEstados = [];
          res.data.estadoOptions.forEach(data => {
            let aux = {};
            aux['value'] = data.id;
            aux['label'] = data.nombre;
            arrayEstados.push(aux);
          })
          setEstadoOptions(arrayEstados);
          setEstado({'value': res.data.estado, 'label': findLabel(arrayEstados, res.data.estado)});
        }
        if (res.data.estudioOptions) {
          var arrayEstudios = [];
          res.data.estudioOptions.forEach(data => {
            let aux = {};
            aux['value'] = data.id;
            aux['label'] = data.nombre;
            arrayEstudios.push(aux);
          })
          setEstudioOptions(arrayEstudios);
          setEstudio({'value': res.data.estudio, 'label': findLabel(arrayEstudios, res.data.estudio)});
        }
        if (res.data.ocupacionOptions) {
          var arrayOcupaciones = [];
          res.data.ocupacionOptions.forEach(data => {
            let aux = {};
            aux['value'] = data.id;
            aux['label'] = data.codigo+ ' ' +data.nombre;
            arrayOcupaciones.push(aux);
          })
          if (res.data.ocupacion) {
            setErrorOcupacion(false);
          }
          setOcupacion({'value': res.data.ocupacion, 'label': findLabel(arrayOcupaciones, res.data.ocupacion)});
        }

        setSexo({'value': res.data.sexo, 'label': findLabel(sexOptions, res.data.sexo)});
        setEdad(res.data.edad);
        setOcupacionYears(res.data.ocupacionYears);
        setResponsabilidad(res.data.responsabilidad);
        setEstres(res.data.estres);
        setComponenteSocial(res.data.componente_social);
        setRelacionTerceros(res.data.relacion_terceros);
        setJefeEquipo(res.data.jefe_equipo);
        setIsLoadForm(true);
      }
    })
    .catch(err => {
      setIsLoadForm(true);
      console.log(err);
    });
  }

  async function saveFormInit() {
    setError(false);
    if (validateFormInt()) {
      await API.post("respuestas/saveFormInit",{
        sexo: sexo.value,
        edad: edad,
        estudio: estudio.value,
        estado: estado.value,
        ocupacion: ocupacion.value,
        ocupacionYears: ocupacionYears,
        responsabilidad: responsabilidad,
        estres: estres,
        componente_social: componente_social,
        relacion_terceros: relacion_terceros,
        jefe_equipo: jefe_equipo
      })
      .then(res => {
        if (res) {
          localStorage.setItem("datos_iniciales", 1);
          setEnviado(true);
        }
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

  const handleChangeSex = selectedOption => {
    setSexo(selectedOption);
  };
  const handleChangeEdad = event => {
    const { value } = event.target;
    if (value < 18) {
      setErrorEdad('Debes ser mayor de edad');
    }
    if (value > 120) {
      setErrorEdad('La edad no puede ser superior a 120');
    }
    if (value == '' || value == null) {
      setErrorEdad('Rellena este campo');
    }
    setEdad(value);
  };
  const handleChangeEstudios = selectedOption => {
    setEstudio(selectedOption);
  };
  const handleChangeEstado = selectedOption => {
    setEstado(selectedOption);
  };
  const handleChangeOcupacion = selectedOption => {
    setError(false);
    setOcupacion(selectedOption);
    loadOcupaciones(selectedOption.value);
  };
  const handleChangeOcupacionYears = event => {
    setError(false);
    const { value } = event.target;
    if (value > 70) {
      setErrorOcupacionYears('Años de ocupacion no puede ser mayor de 70');
    }
    setOcupacionYears(value);
  };

  const triggerBack = () => {
    lastOcupation();
    setOcupacion(null);
    myRef.current.onMenuOpen();
  };


  const classes = useStyles();

  const myRef = useRef(null);

  return (
    <div>
      {enviado == 1 ? <>
        <Redirect to="/participante/index" />
      </> : <>
        <GridContainer>
          {isLoadForm ? <>
            {isEdit &&
              <GridItem xs={12} sm={12} md={12} align="center">
                <Button onClick={() => window.location.href = PARAMS.baseUrl + 'participante/index'} variant="contained" size="medium" color="primary" style={{marginBottom: '15px', backgroundColor: PARAMS.firstColor}}>
                  Volver a Inicio
                </Button>
              </GridItem>
            }
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info" className={classes.cardHeader} style={{backgroundColor: PARAMS.firstColor}}>
                  <Typography gutterBottom variant="h4" component="h2">
                    Formulario Inicial
                  </Typography>
                </CardHeader>
                <CardContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Sexo
                      </Typography>
                      <Select2
                        value={sexo}
                        onChange={handleChangeSex}
                        options={sexOptions}
                        className={(error && sexo.value == null) ? classes.error : ""}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Edad
                      </Typography>
                      <TextField
                        defaultValue={edad}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={handleChangeEdad}
                        error={(errorEdad && (edad == null || edad < 18 || edad > 120 )) ? true : false}
                        helperText={(errorEdad && (edad == null || edad < 18 || edad > 120 )) ? errorEdad : ""}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Nivel de estudios alcanzado (finalizado)
                      </Typography>
                      <Select2
                        value={estudio}
                        onChange={handleChangeEstudios}
                        options={estudioOptions}
                        className={(error && estudio.value == null) ? classes.error : ""}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Situación sentimental actual
                      </Typography>
                      <Select2
                        value={estado}
                        onChange={handleChangeEstado}
                        options={estadoOptions}
                        className={(error && estado.value == null) ? classes.error : ""}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px', display: "flex", flexDirection: 'column'}} >
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Principal ocupación desarrollada en su trayectoria profesional
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        Asegúrate de seleccionar cuatro veces en el desplegable, hasta que aparezcan los cuatro dígitos de la ocupación. <strong>Ejemplo: 4301 Grabadores de datos.</strong>
                      </Typography>
                      <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px', display: "flex", flexDirection: 'row', alignItems: 'center', width: '100%', padding: 0}}>
                        <GridItem xs={2} sm={1} md={1} style={{padding: 0}}>
                          <ButtonGroup style={{backgroundColor: PARAMS.firstColor}} aria-label="outlined primary button group">
                            <Button style={{color: '#fff'}} onClick={() => triggerBack()}>
                              <KeyboardBackspaceIcon/>
                            </Button>
                          </ButtonGroup>
                        </GridItem>
                        <GridItem xs={8} sm={10} md={10} style={{margin: '20px 0px', padding: 0}}>
                          <Select2
                            closeMenuOnSelect={false}
                            value={ocupacion}
                            onChange={handleChangeOcupacion}
                            options={ocupacionOptions}
                            className={(error && ((ocupacion && ocupacion.value == null) || errorOcupacion)) ? classes.error : ""}
                            ref={myRef}
                            isDisabled={selectDisable}
                          />
                        </GridItem>
                        <GridItem xs={2} sm={1} md={1} style={{padding: 0}}>
                          {(isLoad ? <></> : <>
                            <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
                          </>)}
                        </GridItem>
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Años en ocupación
                      </Typography>
                      <TextField
                        defaultValue={ocupacionYears}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={handleChangeOcupacionYears}
                        error={(errorOcupacionYears && (ocupacionYears == null || ocupacionYears > 70 )) ? true : false}
                        helperText={(errorOcupacionYears && (ocupacionYears == null || ocupacionYears > 70 )) ? errorOcupacionYears : ""}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px', marginTop: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        CARACTERÍSTICAS ASOCIADAS AL PUESTO (S) DE TRABAJO QUE HAS OCUPADO LA MAYOR PARTE DE tU TRAYECTORIA LABORAL
                      </Typography>
                      <Typography gutterBottom variant="body1">
                        Indique las características del puesto (o de los puestos) en los que ha desarrollado la mayor parte de su carrera laboral.  Indique la respuesta que mejor se adapta a cada característica.
                      </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px', marginTop: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Responsabilidad de su puesto (s)
                      </Typography>
                      <GridItem style={{flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={responsabilidad == 'Bajo' ? true : false}
                              onChange={() => setResponsabilidad('Bajo')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Bajo"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={responsabilidad == 'Medio' ? true : false}
                              onChange={() => setResponsabilidad('Medio')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Medio"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={responsabilidad == 'Alto' ? true : false}
                              onChange={() => setResponsabilidad('Alto')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Alto"
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Nivel de estrés asociado al puesto (s)
                      </Typography>
                      <GridItem style={{flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={estres == 'Bajo' ? true : false}
                              onChange={() => setEstres('Bajo')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Bajo"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={estres == 'Medio' ? true : false}
                              onChange={() => setEstres('Medio')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Medio"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={estres == 'Alto' ? true : false}
                              onChange={() => setEstres('Alto')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Alto"
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Componente social del puesto (s) (contribución de su trabajo a la sociedad)
                      </Typography>
                      <GridItem style={{flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={componente_social == 'Bajo' ? true : false}
                              onChange={() => setComponenteSocial('Bajo')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Bajo"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={componente_social == 'Medio' ? true : false}
                              onChange={() => setComponenteSocial('Medio')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Medio"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={componente_social == 'Alto' ? true : false}
                              onChange={() => setComponenteSocial('Alto')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Alto"
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Nivel de relación directa con terceros (clientes, proveedores…).
                      </Typography>
                      <GridItem style={{flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={relacion_terceros == 'Bajo' ? true : false}
                              onChange={() => setRelacionTerceros('Bajo')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Bajo"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={relacion_terceros == 'Medio' ? true : false}
                              onChange={() => setRelacionTerceros('Medio')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Medio"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={relacion_terceros == 'Alto' ? true : false}
                              onChange={() => setRelacionTerceros('Alto')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Alto"
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{marginBottom: '15px'}}>
                      <Typography gutterBottom variant="subtitle2" style={{fontWeight: 'bold'}}>
                        Equipo de trabajo a su cargo (dos o más persona)
                      </Typography>
                      <GridItem style={{flexDirection: 'row'}}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={jefe_equipo == 'Si' ? true : false}
                              onChange={() => setJefeEquipo('Si')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="Si"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={jefe_equipo == 'No' ? true : false}
                              onChange={() => setJefeEquipo('No')}
                              value="checkedB"
                              color="primary"
                              classes={{root: classes.radioCustom, checked: classes.checked}}
                            />
                          }
                          label="No"
                        />
                      </GridItem>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{margin: '15px 0px'}} align="center">
                      <ButtonGroup style={{backgroundColor: PARAMS.firstColor}} aria-label="outlined primary button group">
                        <Button style={{color: '#fff'}} onClick={() => saveFormInit()}>
                          {isEdit ? <>Editar</> : <>Enviar</>}
                        </Button>
                      </ButtonGroup>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            </GridItem>
          </> : <>
            <GridItem xs={12} sm={12} md={12} align="center">
              <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={3000} height={80} width={80} />
            </GridItem>
          </>}
        </GridContainer>
      </>}
    </div>
  );
}
