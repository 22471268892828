import React from "react";

// components
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import PARAMS from "utils/PARAMS";

// Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

export default function Actions(props) {
  const { show, onShow, edit, onEdit, del, onDelete, plan, onPlan } = props;

  return (
    <Grid item>
      <ButtonGroup
        style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
        aria-label="outlined primary button group"
      >
        {show && (
          <Button onClick={onShow} title="Mostrar">
            <VisibilityIcon style={{ color: "#fff" }} />
          </Button>
        )}
        {edit && (
          <Button onClick={onEdit} title="Editar">
            <EditIcon style={{ color: "#fff" }} />
          </Button>
        )}
        {del && (
          <Button onClick={onDelete} title="Eliminar">
            <DeleteForeverIcon style={{ color: "#fff" }} />
          </Button>
        )}
        {plan && (
          <Button onClick={onPlan} title="Añadir Planificación">
            <AssignmentIndIcon style={{ color: "#fff" }} />
          </Button>
        )}
      </ButtonGroup>
    </Grid>
  );
}
