import React, { useState, useEffect } from "react";

// components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import API from "utils/API";
import PARAMS from "utils/PARAMS";
import { showSnack } from "utils/helperFunctions";
import Modal from "components/Modal/Modal";

import Button from '@material-ui/core/Button';
import RadarChart from "components/Chart/RadarChart";
import VerticalBarChart from "components/Chart/VerticalBarChart";
import Typography from '@material-ui/core/Typography';
import Loader from 'react-loader-spinner';

import { makeStyles } from "@material-ui/core/styles";

export default function Resultados(props) {

  const [isLoadChart1, setIsLoadChart1] = React.useState(false);
  const [isLoadChart2, setIsLoadChart2] = React.useState(false);
  const [isLoadChart3, setIsLoadChart3] = React.useState(false);

  const [dataRadar1, setDataRadar1] = React.useState([]);
  const [dataRadar2, setDataRadar2] = React.useState([]);
  const [dataRadar3, setDataRadar3] = React.useState([]);
  const [dataRadar4, setDataRadar4] = React.useState([]);
  const [dataRadar5, setDataRadar5] = React.useState([]);
  const [dataRadar6, setDataRadar6] = React.useState([]);
  const [dataRadar7, setDataRadar7] = React.useState([]);
  const [dataVertical, setDataVertical] = React.useState([]);

  const [dataRadarBP1, setDataRadarBP1] = React.useState([]);
  const [bPsychoResultadosTotal, setBPsychoResultadosTotal] = React.useState([]);
  const [bPsychoResultadosParcial, setBPsychoResultadosParcial] = React.useState([]);

  const [dataParticipantes, setDataParticipantes] = React.useState(0);
  const [dataVida, setDataVida] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  }

  const { completado, formPendientes } = props;

  const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 180,
    },
    cardHeader:{
      height: '170px',
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    },
    cardBtn:{
      display: 'flex',
      justifyContent:"center",
      flexDirection:"column"
    }
  };
  const useStyles = makeStyles(styles);

  async function getInitMul() {
    await API.post("respuestas/intMul")
    .then(res => {
      if (res.data) {

        var data = [];
        Object.keys(res.data.dataRadar['1']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['1'][value], B: res.data.mediaRadar['1'][value]});
        })
        setDataRadar1(data);
        var data = [];
        Object.keys(res.data.dataRadar['2']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['2'][value], B: res.data.mediaRadar['2'][value]});
        })
        setDataRadar2(data);
        var data = [];
        Object.keys(res.data.dataRadar['3']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['3'][value], B: res.data.mediaRadar['3'][value]});
        })
        setDataRadar3(data);
        var data = [];
        Object.keys(res.data.dataRadar['4']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['4'][value], B: res.data.mediaRadar['4'][value]});
        })
        setDataRadar4(data);
        var data = [];
        Object.keys(res.data.dataRadar['5']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['5'][value], B: res.data.mediaRadar['5'][value]});
        })
        setDataRadar5(data);
        var data = [];
        Object.keys(res.data.dataRadar['6']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['6'][value], B: res.data.mediaRadar['6'][value]});
        })
        setDataRadar6(data);
        var data = [];
        Object.keys(res.data.dataRadar['7']).map((value, i) => {
          data.push({subject: value, A: res.data.dataRadar['7'][value], B: res.data.mediaRadar['7'][value]});
        })
        setDataRadar7(data);
        // Fix label gráficas radar
        var elements = document.getElementById('intMul').getElementsByClassName("recharts-polar-angle-axis-ticks");
        var fix1 = 0;
        var fix2 = 0;
        var fix3 = 0;
        for (var i = 0; i < elements.length; i++) {
          var ticks = elements[i].getElementsByClassName("recharts-polar-angle-axis-tick");
            switch (i) {
              case 0:
                fix1 = 60;
                fix2 = 70;
                fix3 = 60;
                break;
              case 1:
                fix1 = 100;
                fix2 = 70;
                fix3 = 40;
                break;
              case 2:
                fix1 = 40;
                fix2 = 60;
                fix3 = 50;
                break;
              case 3:
                fix1 = 40;
                fix2 = 90;
                fix3 = 40;
                break;
              case 4:
                fix1 = 50;
                fix2 = 50;
                fix3 = 60;
                break;
              case 5:
                fix1 = 60;
                fix2 = 70;
                fix3 = 60;
                break;
              case 6:
                fix1 = 60;
                fix2 = 40;
                fix3 = 70;
                break;
              default:
            }
            var y = parseInt(ticks[4].getElementsByTagName('text')[0].getAttribute('y')) + fix1;
            ticks[4].getElementsByTagName('text')[0].setAttribute("y", y);
            y = parseInt(ticks[5].getElementsByTagName('text')[0].getAttribute('y')) + fix2;
            ticks[5].getElementsByTagName('text')[0].setAttribute("y", y);
            y = parseInt(ticks[6].getElementsByTagName('text')[0].getAttribute('y')) + fix3;
            ticks[6].getElementsByTagName('text')[0].setAttribute("y", y);


        }

        var data = [];
        Object.keys(res.data.dataBar).map((value, i) => {
          data.push({name: res.data.dataBar[value]['label'], 'Resultado Personal': res.data.dataBar[value]['total'], 'Resultado Media': res.data.dataBar[value]['media']});
        })
        setDataVertical(data);
        // Fix de punto(diamante) de las medias
        var elements = document.getElementById('intMul').getElementsByClassName("recharts-scatter-symbol");
        for (var i = 0; i < elements.length; i++) {
          var x = elements[i].getElementsByTagName('path')[0].getAttribute('x');
          var y = elements[i].getElementsByTagName('path')[0].getAttribute('y') - 15;
          var transform = `translate(${x}, ${y})`;
          elements[i].getElementsByTagName('path')[0].setAttribute("transform", transform);
        }

        setDataParticipantes(res.data.totalMedia);
        setIsLoadChart1(true);
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  async function getBPsycho() {
    await API.post("respuestas/bPsycho")
    .then(res => {
      if (res.data) {
        var data = [];
        Object.keys(res.data.dataBar).map((value, i) => {
          data.push({subject: res.data.dataBar[value]['label'], A: res.data.dataBar[value]['total'], B: res.data.mediaBar[value]['media']});
        })

        setDataRadarBP1(data);

        var data = [];
        var count = 0;
        Object.keys(res.data.dataBar).map((value, i) => {
          switch (res.data.dataBar[value]['label']) {
            case 'Autoaceptación':
              if (res.data.dataBar[value]['total'] > 27) {
                  data[0] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 18 && res.data.dataBar[value]['total'] <= 26) {
                  data[0] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 18) {
                  data[0] = "BAJO";
              }
              break;
            case 'Dominio del entorno':
              if (res.data.dataBar[value]['total'] > 27) {
                  data[1] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 18 && res.data.dataBar[value]['total'] <= 26) {
                  data[1] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 18) {
                  data[1] = "BAJO";
              }
              break;
            case 'Relaciones positivas':
              if (res.data.dataBar[value]['total'] > 27) {
                  data[2] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 18 && res.data.dataBar[value]['total'] <= 26) {
                  data[2] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 18) {
                  data[2] = "BAJO";
              }
              break;
            case 'Crecimiento personal':
              if (res.data.dataBar[value]['total'] > 32) {
                  data[3] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 21 && res.data.dataBar[value]['total'] <= 31) {
                  data[3] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 21) {
                  data[3] = "BAJO";
              }
              break;
            case 'Autonomía':
              if (res.data.dataBar[value]['total'] > 36) {
                  data[4] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 24 && res.data.dataBar[value]['total'] <= 35) {
                  data[4] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 24) {
                  data[4] = "BAJO";
              }
              break;
            default:
              if (res.data.dataBar[value]['total'] > 27) {
                  data[5] = "ALTO";
              }
              if (res.data.dataBar[value]['total'] >= 18 && res.data.dataBar[value]['total'] <= 26) {
                  data[5] = "MEDIO";
              }
              if (res.data.dataBar[value]['total'] < 18) {
                  data[5] = "BAJO";
              }
              break;
          }
          count += res.data.dataBar[value]['total'];
        })
        setIsLoadChart2(true);
        setBPsychoResultadosParcial(data);

        if (count > 176) {
          setBPsychoResultadosTotal("ELEVADO");
        }
        if (count >= 141 && count <= 175) {
          setBPsychoResultadosTotal("ALTO");
        }
        if (count >= 117 && count <= 140) {
          setBPsychoResultadosTotal("MODERADO");
        }
        if (count < 116) {
          setBPsychoResultadosTotal("BAJO");
        }

      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  async function getResultVida() {
    await API.post("respuestas/resultVida")
    .then((res) => {
      var text = "";
      console.log(res);
      if (res.data >= 30 && res.data <= 35){
        text = "<strong>Puntuación muy alta; altamente satisfecho/a.</strong> Las personas encuestadas que obtienen puntuaciones en este rango aman sus vidas y sienten que las cosas van muy bien. Sus vidas no son perfectas, pero sienten que las cosas son tan buenas como es posible que sean. Además, el hecho de que la persona esté satisfecha no significa que sea autocomplaciente o conformista. De hecho, el crecimiento y los desafíos pueden ser parte de la razón por la cual la persona encuestada se encuentra satisfecha. Para la mayoría de las personas en este rango de alto puntuación, la vida es agradable y las principales áreas de la vida van bien: el trabajo o los estudios, la familia, las amistades, el ocio y el desarrollo personal."
      }
      if (res.data >= 25 && res.data <= 29){
        text = "<strong>Puntuación alta.</strong> A las personas que obtienen puntuaciones en este rango les gusta su vida y sienten que las cosas van bien. Por supuesto, sus vidas no son perfectas, pero sienten que las cosas son, en su mayoría, buenas. Además, el hecho de que la persona esté satisfecha no significa que sea autocomplaciente o conformista. De hecho, el crecimiento y los desafíos pueden ser parte de la razón por la cual la persona encuestada se encuentra satisfecha. Para la mayoría de las personas en este rango de alto puntuación, la vida es agradable y las principales áreas de la vida van bien: el trabajo o los estudios, la familia, las amistades, el ocio y el desarrollo personal. La persona puede obtener motivación de las áreas de insatisfacción."
      }
      if (res.data >= 20 && res.data <= 24){
        text = "<strong>Puntuación media.</strong> Esta es la puntuación es media que obtienen los países desarrollados económicamente: la mayoría de las personas generalmente están satisfechas, pero tienen algunas áreas en las que les gustaría mejorar. Algunas personas obtienen puntuaciones en este rango porque, en general, están satisfechas con la mayoría de las áreas de sus vidas, pero ven la necesidad de alguna mejora en cada una de ellas. Otras personas encuestadas puntúan en este rango porque están satisfechas con la mayoría de las áreas de sus vidas, pero tienen una o dos en las que les gustaría experimentar grandes mejoras. Una persona que obtiene puntuación en este rango tiene áreas de su vida que necesita mejorar, dentro de la normalidad. Sin embargo, a una persona en este rango generalmente le gustaría pasar a un nivel superior haciendo algunos cambios en la vida."
      }
      if (res.data >= 15 && res.data <= 19){
        text = "<strong>Ligeramente por debajo del promedio.</strong> Las personas que obtienen puntuaciones en este rango generalmente tienen problemas pequeños pero significativos en varias áreas de sus vidas, o tienen muchas áreas que en las que se encuentran satisfechas, pero un área concreta representa un problema sustancial para ellas. Si una persona ha pasado temporalmente a este nivel de satisfacción vital desde un nivel superior debido a algún acontecimiento reciente, las cosas generalmente mejorarán con el tiempo y, en líneas generales, su nivel de satisfacción volverá a subir. Por otro lado, si una persona está crónicamente ligeramente insatisfecha con muchas áreas de la vida, algunos cambios podrían ser convenientes. A veces la persona simplemente espera demasiado, otras veces es necesario realizar cambios en la vida. Por lo tanto, aunque la insatisfacción temporal es común y normal, un nivel crónico de insatisfacción en varias áreas de la vida requiere reflexión. Algunas personas pueden obtener motivación de un pequeño nivel de insatisfacción, pero, a menudo, la insatisfacción en varias áreas de la vida supone una distracción y también es desagradable"
      }
      if (res.data >= 10 && res.data <= 14){
        text = "<strong>Insatisfecho/a.</strong> Las personas que obtienen puntuaciones en este rango están considerablemente insatisfechas con sus vidas. Las personas en este rango pueden tener varias áreas que no funcionan bien, o una o dos que funcionan muy mal. Si la insatisfacción vital es una respuesta a un acontecimiento reciente como un duelo, un divorcio o un problema importante en el trabajo, la persona probablemente volverá con el tiempo a su nivel anterior de mayor satisfacción. Sin embargo, si los bajos niveles de satisfacción vital son crónicos, es necesario realizar algunos cambios, tanto en las actitudes como en los patrones de pensamiento, y, probablemente, también en las actividades cotidianas. Si persisten los niveles bajos de satisfacción vital en este rango, pueden indicar que las cosas están yendo mal y que se necesitan cambios en el modo de vida. Además, una persona con baja satisfacción vital en este rango a veces no se siente bien porque su infelicidad sirve como una distracción. Hablar con un especialista puede ayudar a la persona a moverse en la dirección correcta, aunque el cambio positivo dependerá de la persona. "
      }
      if (res.data >= 5 && res.data <= 9){
        text = "<strong>Extremadamente insatisfecho.</strong> Las personas que obtienen puntuaciones en este rango están, generalmente, extremadamente descontentas con su vida actual. En algunos casos, esto es una reacción a algún acontecimiento reciente como la viudedad o el desempleo. En otros casos, es una respuesta a un problema crónico como, por ejemplo, el alcoholismo o la adicción. En otros casos, la insatisfacción extrema es debida a una reacción frente a algún suceso negativo en la vida, como haber perdido recientemente a un ser querido. Sin embargo, la insatisfacción a este nivel a menudo se debe a la insatisfacción en múltiples áreas de la vida. Cualesquiera sean las razones del bajo nivel de satisfacción vital, puede ser que se necesite la Ayuda de un profesional. Si la insatisfacción es crónica, la persona necesita realizar cambios y, a menudo, estos pueden ayudar."
      }
      setIsLoadChart3(true);
      setDataVida(text);
    })
    .catch(err => {
      console.log(err);
    });
  }

  function preparePdf(){
    if (completado) {
      var array = {};
      var img0 = document.getElementById("chartBar").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img1 = document.getElementById("chartRadar1").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img2 = document.getElementById("chartRadar2").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img3 = document.getElementById("chartRadar3").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img4 = document.getElementById("chartRadar4").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img5 = document.getElementById("chartRadar5").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img6 = document.getElementById("chartRadar6").getElementsByClassName("recharts-wrapper")[0].outerHTML;
      var img7 = document.getElementById("chartRadar7").getElementsByClassName("recharts-wrapper")[0].outerHTML;

      var imgBP1 = document.getElementById("chartRadarBP1").getElementsByClassName("recharts-wrapper")[0].outerHTML;

      array['intMul'] = [img0, img1, img2, img3, img4, img5, img6, img7];
      array['bPsycho'] = imgBP1;
      array['bPsychoResultadosTotal'] = bPsychoResultadosTotal;
      array['bPsychoResultadosParcial'] = bPsychoResultadosParcial;
      array['satisVida'] = dataVida;
      array['participantes'] = dataParticipantes;
      props.dataPdf(array);
      console.log(array);
    }else{
      setOpen(true);
    }
  }

  async function generatePdf(data) {
    let axiosApi = API;
    axiosApi.defaults.responseType = 'blob';
    await axiosApi.post("respuestas/generatePdf",{
      data: data
    })
    .then(res => {
      if (res) {
        //Ver en linea
        // const url = window.URL.createObjectURL(new Blob([res.data], {type: 'application/pdf'}));
        // window.open(url);
        // Descarga
        let url;
        if(window.URL){
          url = window.URL.createObjectURL(new Blob([res.data]));
        }else{
          url = window.webkitURL.createObjectURL(new Blob([res.data]));
        }
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
  }

  useEffect(() => {
    getInitMul();
    getBPsycho();
    getResultVida();
  }, []);

  useEffect(() => {
    if (completado && isLoadChart1 && isLoadChart2 && isLoadChart3) {
      setTimeout(function () {
        preparePdf()
      }, 3000);
    }
  }, [completado, isLoadChart1, isLoadChart2, isLoadChart3]);

  function modalNoDisponible(){
    return(
      <GridItem xs={12} sm={12} md={12}>
        Debes terminar los formularios
        <Typography gutterBottom variant="p" component="p" style={{color: '#000', fontWeight: 'bold'}}>
          {formPendientes.join(', ')}
        </Typography>
      </GridItem>
    )
  }

  const classes = useStyles();
  const colors = ["#D8A4A8", "#BD6171", "#66344D", "#674B6B", "#0F5A94", "#338BBF", "#B7CAD6" ];

  return (
    <GridContainer style={{display: 'flex', justifyContent: 'center'}}>
      <div id="intMul">
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar1">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar1} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar2">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar2} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar3">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar3} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar4">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar4} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar5">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar5} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar6">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar6} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartRadar7">
            <RadarChart stroke1="#c05e6d" fill1="#c05e6d" stroke2="#2C6CA2" fill2="transparent" data={dataRadar7} />
          </div>
        </GridItem>
        <GridItem style={{display: 'none'}}>
          <div id="chartBar">
            <VerticalBarChart data={dataVertical} dataColor={colors} showMedia={true} colorScatter="#666" />
          </div>
        </GridItem>
      </div>
      <div id="bPsycho">
        <GridItem style={{display: 'none'}}>
          <div id="chartRadarBP1">
            <RadarChart stroke1="#2C6CA2" fill1="#2C6CA2" stroke2="#c05e6d" fill2="transparent" data={dataRadarBP1} />
          </div>
        </GridItem>
      </div>
      <Modal
        open={open}
        onConfirm={() => handleClose()}
        content={modalNoDisponible()}
        onlyConfirm={true}
        confirmText="Ok"
        confirmIcon={<></>} />
    </GridContainer>
  );
}
