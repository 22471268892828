import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import PARAMS from "utils/PARAMS";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import flechaImg from "assets/img/landing/flecha.png";
import lapizImg from "assets/img/landing/lapiz.png";
import manoImg from "assets/img/landing/mano.png";
import matrazImg from "assets/img/landing/matraz.png";
import modeloImg from "assets/img/landing/modelo.png";
import altavozImg from "assets/img/landing/altavoz.png";
import naveImg from "assets/img/landing/nave.png";
import regaloImg from "assets/img/landing/regalo.png";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    width: '55px',
    height: '55px',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Landing(props) {
  const classes = useStyles();
  const { onLogin, onGetClave } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar style={{backgroundColor: PARAMS.firstColor}}>
          <Typography variant="h6" color="inherit" noWrap >
            Abay Talent
          </Typography>
        </Toolbar>
      </AppBar>
      <main style={{width: '100%'}}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              <img src={matrazImg} class={classes.cardMedia} />
              Te llevamos a nuestro laboratorio de ideas porque necesitamos de tu colaboración, pero será sencilla y sin coste y, además, recibirás tu recompensa.
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              En el diseño de un nuevo servicio sobre detección de talentos para la definición de carreras profesionales que proporcionen altos niveles de satisfacción y autorrealización vital, es preciso conocer información relativa a las capacidades e itinerarios laborales de una muestra representativa de profesionales.
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              El proceso en el que necesitamos tu colaboración es sencillo…
            </Typography>

            <Grid container style={{padding: '0px 5%'}}>
              <Grid item xs={12} sm={6} md={3} style={{padding: '25px'}}>
                <img src={manoImg} class={classes.cardMedia} style={{marginLeft: '15%'}}/>
                <img align="right" src={flechaImg} class={classes.cardMedia} />
                <Typography variant="h6" align="left" color="textSecondary" paragraph
                style={{marginTop: '15px'}}>
                  Pincha en el enlace para generar la clave
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{padding: '25px'}}>
                <img src={lapizImg} class={classes.cardMedia} style={{marginLeft: '15%'}}/>
                <img align="right" src={flechaImg} class={classes.cardMedia} />
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{marginTop: '15px'}}>
                  Accede con tu clave y cumplimenta el cuestionario y finaliza con su envio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{padding: '25px'}}>
                <img src={regaloImg} class={classes.cardMedia} style={{marginLeft: '15%'}}/>
                <img align="right" src={flechaImg} class={classes.cardMedia} />
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{marginTop: '15px'}}>
                  Obtendrás tu recompensa: un informe referido a tus principales talentos atendiendo a la teoría de las Inteligencias Múltiples
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{padding: '25px'}}>
                <img src={altavozImg} class={classes.cardMedia} style={{marginLeft: '15%'}}/>
                <Typography variant="h6" align="left" color="textSecondary" paragraph style={{marginTop: '15px'}}>
                  Si tienes interés en el desarrollo de esta idea q estamos testeando, te tendremos informado
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="h5" align="left" color="textSecondary" paragraph style={{marginBottom: '50px'}}>
              … pero requiere de sinceridad.
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              Como agradecimiento, recibirás como recompensa un informe con los resultados obtenidos en el test de inteligencias múltiples, lo que te permitirá identificar en qué área tienes tus principales talentos. ¡Atrévete a descubrirte!
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              Queremos destacar ante todo que este es un experimento totalmente anónimo para el que no se solicita ningún dato personal ni se almacenarán referencias de ningún usuario. Accederás de manera segura y totalmente confidencial, a través de una clave que solo tú conoces, al cuestionario y al informe de resultados.
            </Typography>

            <Typography variant="h5" align="left" color="textSecondary" paragraph style={{margin: '30px 0px'}}>
              <img src={naveImg} class={classes.cardMedia} />
              A dónde queremos llegar:
            </Typography>
            <Typography variant="h5" align="left" color="textSecondary" paragraph>
              Se trata de un proyecto de Inteligencia Artificial basado en modelos que establecen relaciones entre los talentos de cada persona y las ocupaciones más idóneas para su desarrollo personal y profesional.
            </Typography><Typography variant="h5" align="left" color="textSecondary" paragraph>
              Con la recogida de datos, podremos generar algoritmos que relacionen talentos, ocupaciones y niveles de felicidad.
            </Typography>

            <div className={classes.heroButtons} style={{paddingBottom: 30}}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={onGetClave} style={{backgroundColor: PARAMS.firstColor}}>
                    Obtener claves personales
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={onLogin} style={{color: PARAMS.firstColor, textShadow: '1px 1px 1px rgba(0,0,0,0.6)'}}>
                    Introducir claves personales
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

      </main>
    </React.Fragment>
  );
}
